/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from "react";
import { Translate } from "../utils/Translate";
import { Avatar, Grid, Typography } from "@material-ui/core";
import AccountButtonDotsMobile from "./AccountButtonDotsMobile";
import Routes from "../components/Router/Routes";
import { useLocation, useNavigate } from "react-router";
import { SearchItems } from "./SearchItems";
import IconSearch from "CommonImages/icon-search";
const config = require("../assets/config.json");

const LoggedInTexts = (props) => {
  const history = useNavigate();

  const location = useLocation();
  const findString = "/search/";
  const checkOnSearchPage = () => {
    return window.location.pathname.includes(findString);
  };
  const getStateData = () => {
    const showSearch = checkOnSearchPage();
    let [, searchText] = showSearch
      ? window.location.pathname.split(findString)
      : "";
    if (searchText) searchText = decodeURI(searchText);
    return { showSearch, searchText };
  };

  const handelSearch = () => {
    if (
      window.location.href.includes("classes/search") ||
      !window.location.href.includes("search")
    ) {
      props.handelSearchBox(true);
    }
  };

  const closeSearch = () => {
    props.handelSearchBox(false);
  };
  const handelShowResultPage = () => {
    const inputEle = document.querySelector(".text-box input");
    if (inputEle?.value?.length) {
      const query = encodeURI(inputEle?.value ?? "");
      const isSearchPage = checkOnSearchPage();
      props.setSearchQuery(
        query,
        isSearchPage ? props.pageFrom : window.location.pathname
      );
      history(`/search/${query}`);
      props.handelSearchBox(false);
    }
  };
  const handleClickToFocus = () => {
    const inputEle = document.querySelector(".text-box input");
    inputEle.focus();
  };
  return (
    <React.Fragment>
      <div className="loginButtonHolder">
        <Grid container justifyContent="flex-end" alignItems="center">
          {props.showSearch === false && (
            <>
              {(config.showLiveEvent || config.ShowLiveConnect) && (
                <Grid item>
                  <a
                    className={
                      location.pathname === Routes.connectClasses ||
                      location.pathname.indexOf("live") > -1 ||
                      location.pathname.indexOf("connect") > -1
                        ? `headerLinks-fitness ${config.name} dynamiclinks`
                        : "headerLinks-fitness"
                    }
                    onClick={() => {
                      config.showLiveEvent
                        ? history(Routes.connectLiveSchedule)
                        : history(Routes.connectOndemandClasses);
                    }}
                  >
                    <Typography variant="button">
                      {Translate({ id: "navigation.ConnectTopNav" })}
                    </Typography>
                  </a>
                </Grid>
              )}
              {config.showBrowseClasses && (
                <Grid item>
                  <a
                    className={
                      location.pathname === Routes.classListViewRoute ||
                      location.pathname === Routes.searchRoute ||
                      window.location.href.indexOf("categories") > -1
                        ? `headerLinks-fitness ${config.name} dynamiclinks`
                        : "headerLinks-fitness"
                    }
                    onClick={() => {
                      history(Routes.classListViewRoute);
                    }}
                  >
                    <Typography variant="button">
                      {Translate({ id: "navigation.ClassesTopNav" })}
                    </Typography>
                  </a>
                </Grid>
              )}
              {config.showChannels && (
                <Grid item>
                  <a
                    className={
                      location.pathname === "/channels" ||
                      window.location.href.indexOf("channels") > -1
                        ? `headerLinks-fitness ${config.name} dynamiclinks`
                        : "headerLinks-fitness"
                    }
                    onClick={() => {
                      history("/channels");
                    }}
                  >
                    <Typography variant="button">
                      {Translate({ id: "navigation.ChannelsTopNav" })}
                    </Typography>
                  </a>
                </Grid>
              )}
              <Grid item>
                <a
                  className={
                    location.pathname === Routes.collections ||
                    window.location.href.indexOf("collection") > -1
                      ? `headerLinks-fitness ${config.name} dynamiclinks`
                      : "headerLinks-fitness"
                  }
                  style={{
                    marginRight: "10px",
                  }}
                  onClick={() => {
                    history(Routes.collections);
                  }}
                >
                  <Typography variant="button">
                    {Translate({ id: "navigation.CollectionsTopNav" })}
                  </Typography>
                </a>
              </Grid>
              {(window.location.href.includes("classes/search") ||
                !window.location.href.includes("search")) && (
                <Grid item id="search-icon" className="item-search">
                  <div className="icon-search" onClick={handelSearch}>
                    <IconSearch />
                  </div>
                </Grid>
              )}
              {window.location.href.includes("search") &&
                !window.location.href.includes("classes/search") && (
                  <Grid item className="item-search">
                    <div
                      className="icon-search dynamicLink"
                      onClick={handleClickToFocus}
                    >
                      <IconSearch color={config.primaryColor} />
                    </div>
                  </Grid>
                )}
              <Grid item>
                <Avatar
                  style={{ cursor: "pointer" }}
                  alt="Travis Howard"
                  // src={avatarImg}
                  onClick={() => {
                    history(Routes.favClassRoute);
                  }}
                />
              </Grid>
            </>
          )}
          {props.showSearch === true &&
            (window.location.href.includes("classes/search") ||
              !window.location.href.includes("search")) && (
              <Grid item id="search-box" className="item-search">
                <SearchItems
                  focus
                  handelBack={handelShowResultPage}
                  closeSearch={closeSearch}
                  loading={props.globalSearchLoading}
                  showSearch={props.showSearch}
                  crossIconId={"crossIconId"}
                />
              </Grid>
            )}
        </Grid>
      </div>

      <div className="loginButtonHolderMobile pull-right">
        <Grid container justifyContent="flex-end" alignItems="center">
          <Grid item>
            <Avatar
              alt="Travis Howard"
              onClick={() => {
                history(Routes.favClassRoute);
              }}
            />
          </Grid>
          {/* <AccountButtonDotsMobile></AccountButtonDotsMobile> */}
          <AccountButtonDotsMobile
            searchText={getStateData().searchText}
            handelBack={handelShowResultPage}
            loading={props.globalSearchLoading}
            isSearchPage={checkOnSearchPage()}
          />
        </Grid>
      </div>
    </React.Fragment>
  );
};
export default LoggedInTexts;
