var _Environments = {
  production: {
    BASE_URL: "https://api.wexer.com/",
    API_KEY: "a0ddf4dc5b0b4b628e5a4c27b93c988e",
    API_SECRET: "711ff01003764889987158b329c7e6c0",
    RESET_PASSWORD: "https://bhliveactive.gladstonego.cloud/identity/Account/ForgottenPassword",
    JWPLAYER:'95riWD5s.js'
  },
  test: {
    BASE_URL: "https://test.api.wexer.com/",
    API_KEY: "1234123412341234",
    API_SECRET: "573572796441127398913495789868468206481",
    RESET_PASSWORD: "https://my.bhliveactive.org.uk/Connecttrn/MemberManagement/MRMpasswordrequest.aspx",
    JWPLAYER:'HofBP5zj.js' 
  },
  development: {
    BASE_URL: "https://ff-int.api.wexer.com/",
    API_KEY: "1234123412341234",
    API_SECRET: "573572796441127398913495789868468206481",
    RESET_PASSWORD: "https://my.bhliveactive.org.uk/Connecttrn/MemberManagement/MRMpasswordrequest.aspx",
    JWPLAYER:'HofBP5zj.js' 
  },
  stage: {
    BASE_URL: "https://stage.api.wexer.com/",
    API_KEY: "1234123412341234",
    API_SECRET: "573572796441127398913495789868468206481",
    RESET_PASSWORD: "https://bhliveactive.gladstonego.cloud/identity/Account/ForgottenPassword",
    JWPLAYER:'wUkkXJdm.js'
  },
};

function getEnvironment() {
  const PLATFORM = process.env.REACT_APP_PLATFORM;

    let platform  //getPlatform();
    if (PLATFORM === "FF-INT") {
      platform = "development";
    } else if (PLATFORM === "TEST") {
      platform = "test";
    } else if (PLATFORM === "STAGE") {
      platform = "stage";
    } else if (PLATFORM === "PROD") {
      platform = "production";
    } 
  
    return _Environments[platform]  
}

var Environment = getEnvironment();
module.exports = Environment;


 